/*
    Costumization by: nexum AG
    Version : 1.0
    Author : Johannes Mickel
    Website : https://www.nexum.com/
    Support : johannes.mickel@nexum.com
*/
/*
    TABLE OF CONTENT
    1 Components        
    2 Sections / .section
        2.1 Section #home
    3 Page
    4 Forms
*/

/* 0 Migration to Tailwind CSS */
/* We need scoped preflight classes for the time being until we have migrated all old CSS to Tailwind */

.tw-preflight-fix-border {
  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
    border-color: theme('borderColor.DEFAULT', currentColor);
  }
}

.tw-preflight-reset-font {
  *,
  ::before,
  ::after {
    font-size: initial;
    font-weight: initial;
    margin: initial;
    padding: initial;
  }
}

/* 0.1 Tailwind helpers */
.tw-custom-divider-on-light {
  border-top: 1px solid transparent; /* Fallback if border-image is not supported */
  border-image: repeating-linear-gradient(
    to right,
    rgb(70, 70, 70),
    rgb(70, 70, 70) 10px,
    transparent 10px,
    transparent 20px
  );
  border-image-slice: 1;
  border-image-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
}

.tw-custom-divider-on-dark {
  border-top: 1px solid transparent; /* Fallback if border-image is not supported */
  border-image: repeating-linear-gradient(
    to right,
    rgb(178, 178, 178),
    rgb(178, 178, 178) 10px,
    transparent 10px,
    transparent 20px
  );
  border-image-slice: 1;
  border-image-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
}

/* 1 Components */
/* 1.1 Navigation */
/* make hamburger menu topmost at all times */
.navbar-top-most {
  z-index: 1000;
}

/* prevent hamburger menu from displaying an annoying white outline in smaller viewports */
.navbar-dark .navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

/* indent level 2 of navigation */
.level-2 {
  margin-left: 1.5em !important;
}

/* 1.2 Icons (and Images) */
.img-container {
  width: 100%;
  /* Use the full width of the parent container */
  position: relative;
  /* Needed for absolute positioning of the pseudo-element */
}

.img-container::before {
  content: '';
  display: block;
  padding-top: 100%;
  /* This creates a square aspect ratio */
}

.img-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the full area */
}

.nx-logo {
  max-width: 150px;
}

.nx-img-full-width {
  width: 100%;
}

.nx-img-max-half-width {
  max-width: 50%;
}

/* 1.10 Carousel, slider , scrolls */
@media (max-width: 991.98px) {

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 1rem;
  }
}

/* 2 Sections */
/* Section #stageSimple */


/* Section #home */

/* Add margin below element in viewport MD and smaller */
@media (max-width: 991.98px) {
  .footer-scrolldown {
    margin-bottom: 3rem;
  }
}

/* Section header */
.navbar-a .navbar-brand .light-logo {
  max-width: 150px;
}

.logo img {
  width: 100%;
}

/* Section #bcb-intro */
.title-desc .light-logo {
  max-width: 10em;
}

/* Section #bcb-intro */
#bcb-intro,
#result,
#intro,
#stageProduct, 
#stageSimple,
#success {
  min-height: 100vh;
}

#bcb-intro,
#result {
  background-color: #000;
  color: #fff;
}

/* Section #leistungsversprechen */
#leistungsversprechen {
  background-color: #fff;
}

.s-curve {
  height: 100%;
  background-image: url(../../assets/img/s-curve_sans.svg);
  background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
}

/* Section #more-projects */
#more-projects {
  background-color: #fff;
}

.section-title .title-top {
  z-index: 999;
}

/* Section hero-footer */
.mark {
  color: #000;
  margin: 12px -0.2em;
  border-radius: 0.25em;
  background: transparent;
  background-color: #FBE54E;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Smooth transition for hover and focus */
}

/* Hover state */
.mark:hover {
  background-color: rgb(0, 0, 0);
  /* Lighter shade for hover */
  color: #ffffff;
  /* Darker text color for better contrast */
}

/* Focus state */
.mark:focus {
  background-color: rgb(0, 0, 0);
  /* Same as hover or you can choose another color */
  color: #ffffff;
  outline: none;
  /* Optionally remove default outline */
  box-shadow: 0 0 0 2px rgba(252, 248, 71, 0.5);
  /* Adding a shadow for visual focus indicator */
}

.mark-stage {
  margin-top: 1em;
  line-height: 1.2em;
}

/* for paragraphs of text we will add a little space so that the marker doesn't obstruct the view of the rest of the text */
.mark-paragraph::before {
  content: '\00a0';
  /* Non-breaking space */
}

.mark-paragraph::after {
  content: '\00a0';
  /* Non-breaking space */
}

/* Section products */
.nx-item-link {
  padding: 2.6em 0 1.6em 0;
}

#produkte .teaser-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
  /* 1:1 Aspect Ratio */
  overflow: hidden;
  background-color: #eeeeee;
}

#produkte .teaser-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#produkte .teaser-text {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  color: #0f0f0f;
  width: 80%;
}

#produkte .teaser .button {
  display: inline-block;
  padding: 0.5em 1em;
  margin-top: 1em;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.product-teaser-border {
  border-top: solid #000 0.5rem;
}

.topic-teaser {
  background-color: #fff;
  border: 1rem solid #eaeae0;
  margin: 0;
  padding: 1.5rem;
}

.topic-teaser-linkstyle {
  color: inherit;
  text-decoration: none;
}

/* Section description02 */
#description02 {
  background-color: #FBE54E;
}

#nx-section-title-white {
  @media screen and (max-width: 768px) {
    color: #fff;
  }

  color: #000;
}

/* Section mission02 */
#mission02 {
  height: 75vh;
}

/* Section SPACER */
.spacer {
  min-height: 133vh;
}

/* Section m03 Value Proposition and m19 Value Proposition Product*/
.bg-figure {
  background-image: url(../../assets/img/items/img-square-6.svg);
  background-size: cover;
  height: 560px;
  width: 50%;
}

/* 3 Page */
.videowrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.videowrapper .videowrapper__video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.videohelper {
  position: fixed;
  top: 0;
  z-index: -1;
}

.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.02);
}

/* 4 Forms */

.btn-primary {
  color: #fff !important;
  background-color: #000000;
  border-color: #000000;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  position: relative;
  z-index: 1;
  border-width: 1.5px;
}

.btn-primary:active {
  color: #000 !important;
  background-color: rgb(255, 255, 255);
  border-color: rgb(0, 0, 0);
  transform: scale(0.98);
}

.btn-primary:hover {
  color: #000 !important;
  background-color: rgb(255, 255, 255);
  border-color: rgb(0, 0, 0);
  transform: scale(1.02);
}

.btn-primary:focus {
  color: #fff !important;
  background-color: #000000;
  border-color: #000000;
  box-shadow: 0 0 0 .25rem #8c8c8c80;
}

.form-description-height {
  height: 12em;
}

/* 5 Helpers */

.wordWrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.hyphens {
  hyphens: auto;
}

.nx-teaser-float-left {
  @media (min-width: 992px) {
    float: left !important;
    padding: 0 3.2rem 3.2rem 0;
  }
}

.nx-teaser-float-right {
  @media (min-width: 992px) {
    float: right !important;
    padding: 0 0 3.2rem 3.2rem;
  }
}

.nx-teaser-transgress-left-2 {
  @media (min-width: 992px) {
    margin-left: -25%;
    width: calc(50% + 25%);
  }
}

.nx-teaser-transgress-right-2 {
  @media (min-width: 992px) {
    margin-right: -25%;
    width: calc(50% + 25%);
  }
}

.headline-letterspacing-narrow {
  letter-spacing: -0.05rem;
}

.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-grey {
  background-color: #eaeae0;
}

.bg-grey-2 {
  background-color: hsl(60, 19%, 95%);
}

.color-digitaltransformation {
  background-color: #F88864;
}

.color-digitalstrategyconsulting {
  background-color: #FBE54E;
}

.color-processoptimization {
  background-color: #F38C96;
}

.color-databased {
  background-color: #F398C1;
}

.color-generativeai {
  background-color: #BAB5EB;
}

.color-newbusiness {
  background-color: #D4E766;
}

.color-businessbuilding {
  background-color: #71CE9B;
}

.font-color-white {
  color: #fff;
}

.font-color-black {
  color: #000;
}

.display-none {
  display: none;
}

.z-index-1 {
  z-index: 1;
}

.z-index-999 {
  z-index: 999;
}

.iframe-no-display {
  display: none;
  visibility: hidden;
}