.tw-preflight-fix-border {
  & *, & :before, & :after {
    border: 0 solid #e5e7eb;
  }
}

.tw-preflight-reset-font {
  & *, & :before, & :after {
    font-size: initial;
    font-weight: initial;
    margin: initial;
    padding: initial;
  }
}

.tw-custom-divider-on-light {
  border-top: 1px solid #0000;
  border-image: repeating-linear-gradient(to right, #464646, #464646 10px, #0000 10px 20px) 1 / 1px;
}

.tw-custom-divider-on-dark {
  border-top: 1px solid #0000;
  border-image: repeating-linear-gradient(to right, #b2b2b2, #b2b2b2 10px, #0000 10px 20px) 1 / 1px;
}

.navbar-top-most {
  z-index: 1000;
}

.navbar-dark .navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.level-2 {
  margin-left: 1.5em !important;
}

.img-container {
  width: 100%;
  position: relative;
}

.img-container:before {
  content: "";
  padding-top: 100%;
  display: block;
}

.img-container img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.nx-logo {
  max-width: 150px;
}

.nx-img-full-width {
  width: 100%;
}

.nx-img-max-half-width {
  max-width: 50%;
}

@media (width <= 991.98px) {
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 1rem;
  }

  .footer-scrolldown {
    margin-bottom: 3rem;
  }
}

.navbar-a .navbar-brand .light-logo {
  max-width: 150px;
}

.logo img {
  width: 100%;
}

.title-desc .light-logo {
  max-width: 10em;
}

#bcb-intro, #result, #intro, #stageProduct, #stageSimple, #success {
  min-height: 100vh;
}

#bcb-intro, #result {
  color: #fff;
  background-color: #000;
}

#leistungsversprechen {
  background-color: #fff;
}

.s-curve {
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-image: url("s-curve_sans.1478a5ff.svg");
  background-size: 100% 100%;
  height: 100%;
}

#more-projects {
  background-color: #fff;
}

.section-title .title-top {
  z-index: 999;
}

.mark {
  color: #000;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background: #fbe54e;
  border-radius: .25em;
  margin: 12px -.2em;
  transition: background-color .3s, color .3s;
}

.mark:hover {
  color: #fff;
  background-color: #000;
}

.mark:focus {
  color: #fff;
  background-color: #000;
  outline: none;
  box-shadow: 0 0 0 2px #fcf84780;
}

.mark-stage {
  margin-top: 1em;
  line-height: 1.2em;
}

.mark-paragraph:before, .mark-paragraph:after {
  content: " ";
}

.nx-item-link {
  padding: 2.6em 0 1.6em;
}

#produkte .teaser-wrapper {
  background-color: #eee;
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

#produkte .teaser-image {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#produkte .teaser-text {
  text-align: left;
  color: #0f0f0f;
  width: 80%;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

#produkte .teaser .button {
  color: #fff;
  background-color: #000;
  border-radius: 5px;
  margin-top: 1em;
  padding: .5em 1em;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.product-teaser-border {
  border-top: .5rem solid #000;
}

.topic-teaser {
  background-color: #fff;
  border: 1rem solid #eaeae0;
  margin: 0;
  padding: 1.5rem;
}

.topic-teaser-linkstyle {
  color: inherit;
  text-decoration: none;
}

#description02 {
  background-color: #fbe54e;
}

#nx-section-title-white {
  color: #000;

  @media screen and (width <= 768px) {
    & {
      color: #fff;
    }
  }
}

#mission02 {
  height: 75vh;
}

.spacer {
  min-height: 133vh;
}

.bg-figure {
  background-image: url("img-square-6.a5370999.svg");
  background-size: cover;
  width: 50%;
  height: 560px;
}

.videowrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.videowrapper .videowrapper__video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.videohelper {
  z-index: -1;
  position: fixed;
  top: 0;
}

.grow {
  transition: all .3s ease-in-out;
}

.grow:hover {
  transform: scale(1.02);
}

.btn-primary {
  z-index: 1;
  background-color: #000;
  border-width: 1.5px;
  border-color: #000;
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
  color: #fff !important;
}

.btn-primary:active {
  background-color: #fff;
  border-color: #000;
  transform: scale(.98);
  color: #000 !important;
}

.btn-primary:hover {
  background-color: #fff;
  border-color: #000;
  transform: scale(1.02);
  color: #000 !important;
}

.btn-primary:focus {
  background-color: #000;
  border-color: #000;
  box-shadow: 0 0 0 .25rem #8c8c8c80;
  color: #fff !important;
}

.form-description-height {
  height: 12em;
}

.wordWrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.hyphens {
  hyphens: auto;
}

.nx-teaser-float-left {
  @media (width >= 992px) {
    & {
      padding: 0 3.2rem 3.2rem 0;
      float: left !important;
    }
  }
}

.nx-teaser-float-right {
  @media (width >= 992px) {
    & {
      padding: 0 0 3.2rem 3.2rem;
      float: right !important;
    }
  }
}

.nx-teaser-transgress-left-2 {
  @media (width >= 992px) {
    & {
      width: 75%;
      margin-left: -25%;
    }
  }
}

.nx-teaser-transgress-right-2 {
  @media (width >= 992px) {
    & {
      width: 75%;
      margin-right: -25%;
    }
  }
}

.headline-letterspacing-narrow {
  letter-spacing: -.05rem;
}

.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-grey {
  background-color: #eaeae0;
}

.bg-grey-2 {
  background-color: #f5f5f0;
}

.color-digitaltransformation {
  background-color: #f88864;
}

.color-digitalstrategyconsulting {
  background-color: #fbe54e;
}

.color-processoptimization {
  background-color: #f38c96;
}

.color-databased {
  background-color: #f398c1;
}

.color-generativeai {
  background-color: #bab5eb;
}

.color-newbusiness {
  background-color: #d4e766;
}

.color-businessbuilding {
  background-color: #71ce9b;
}

.font-color-white {
  color: #fff;
}

.font-color-black {
  color: #000;
}

.display-none {
  display: none;
}

.z-index-1 {
  z-index: 1;
}

.z-index-999 {
  z-index: 999;
}

.iframe-no-display {
  visibility: hidden;
  display: none;
}
/*# sourceMappingURL=index.4264fef6.css.map */
